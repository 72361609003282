<template>
  <div class="px-4 px-md-5">
    <h3 class="text-center mb-2">NLU Web Portal</h3>
    <h6 class="text-center text-muted">Account Registration</h6>
    <div class="row justify-content-center py-md-2">
      <div class="col-lg-9">
        <b-form @submit.prevent="submit">
          <b-form-group
            label="Email address"
            invalid-feedback="Please type a valid email"
          >
            <b-form-input
              v-model="$v.form.email.$model"
              type="email"
              placeholder="For example johndoe@example.com"
              :state="validateState($v.form.email)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="First Name" invalid-feedback="First Name is required">
            <b-form-input
              v-model="$v.form.first_name.$model"
              type="text"
              placeholder="For example John"
              :state="validateState($v.form.first_name)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Last Name" invalid-feedback="Last Name is required">
            <b-form-input
              v-model="$v.form.last_name.$model"
              type="text"
              placeholder="For example Doe"
              :state="validateState($v.form.last_name)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Telephone"
            invalid-feedback="Telephone is required"
          >
            <b-form-input
              v-model="$v.form.phone_no.$model"
              type="tel"
              placeholder="For example 074000000"
              :state="validateState($v.form.phone_no)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-form-group invalid-feedback="Agreement is obligatory">
            <b-form-checkbox
              v-model="$v.form.terms.$model"
              :state="validateState($v.form.terms)"
              >I agree all statements in terms of service</b-form-checkbox
            >
          </b-form-group>
          <b-button
            variant="danger"
            type="submit"
            class="w-100 mt-2"
            :disabled="submitting"
          >
            <b-spinner small v-show="submitting"></b-spinner>&nbsp;
            Register</b-button
          >
        </b-form>
      </div>
    </div>
    <register-notification :msg="msg" :modalShow.sync="modalShow"></register-notification>
  </div>
</template>
<script>
import axios from "axios";
import RegisterNotification from "./components/RegisterNotification.vue"
import { validationMixin } from "vuelidate";
import { required, email, sameAs,helpers } from "vuelidate/lib/validators";
const BASE_URL = process.env.VUE_APP_BACKEND + "/auth";
const number = helpers.regex("serial",/^07[0-9]{8}$/);

export default {
  mixins: [validationMixin],
  components:{RegisterNotification},
  data() {
    return {
      form: {
        email: "",
        first_name: "",
        last_name:"",
        phone_no: "",
        terms: null,
      },
      submitting: false,
      msg:"",
      modalShow:false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      first_name: {
        required,
      },
      last_name:{
        required
      },
      phone_no: {
        required,
        number
      },
      terms: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.submitting = true;
      axios({
        method:"post",
        data:this.form,
        url:BASE_URL+"/register"
      }).then((res) => {
        this.submitting = false;
        this.resetForm();
        this.msg = res.data;
        this.modalShow = true;
      }).catch((error)=>{
        if(error.response.status==422){
          this.msg=error.response.data['message'];
          this.modalShow=true;
          this.submitting =false;
        }
      });
    },
    resetForm() {
      this.$v.form.$reset();
    },
  },
};
</script>