<template>
    <b-modal id="register-notification" title="Operation Status" hide-footer v-model="modalShow" @hide="onClose">
        {{msg}}    
    </b-modal>
</template>
<script>
export default {
    props:{
        msg:String,
        modalShow:Boolean
    },
    methods:{
        onClose(){
            window.close();
        }
    }
}
</script>